import { useEffect, useState } from "react";
import { screens } from "tailwindcss/defaultTheme";

export const useScreens = () => {
  /**
   * Initialize this as undefined so server and client renders match
   */
  const [dimensions, setDimensions] = useState({
    width: undefined,
    screen: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      /**
       * Loop through the tailwind screens (ordered from md to 2xl) and if the media match
       * is true, overwrite the match. That will be the largest-matching and current screen
       * and breakpoint. As Tailwind is mobile-first, there is not a breakpoint for <640px,
       * so we are defaulting the iterator to "sm" to capture that default state.
       */
      const screen = Object.entries(screens).reduce(
        (match, [name, breakpoint]) => {
          const matchQuery = `(min-width: ${breakpoint})`;

          if (window.matchMedia(matchQuery).matches) {
            return name;
          }

          return match;
        },
        "sm"
      );

      setDimensions({
        width,
        screen,
      });
    };

    /* Add event listener... */
    window.addEventListener("resize", handleResize);

    /* ...but call the fx right away to update state with first values */
    handleResize();

    /* Cleanup the event listener */
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimensions;
};
