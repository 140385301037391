import { useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { CaretLeft, CaretRight } from "@icons";

export function Controls({
  onPrev,
  onNext,
  scrollPosition = { head: 0, tail: null },
  className = "",
  totalItemsCount = 1,
  activeItem = 1,
  indicators = false,
}) {
  const { head, tail } = scrollPosition;

  useEffect(() => {}, []);

  return (
    <div className={cn("flex text-black-300", className)}>
      <button
        className={cn({
          "cursor-default opacity-50": head === 0,
          "mr-2": !indicators,
        })}
        onClick={onPrev}
        disabled={head === 0}
        aria-label="previous slide"
        type="button"
      >
        <CaretLeft className="text-3xl" />
      </button>
      {indicators && (
        <div className="w-24 mx-auto text-center text-sm font-semibold text-black-300">
          {activeItem} / {totalItemsCount}
        </div>
      )}
      <button
        className={cn({ "cursor-default opacity-50": tail === 0 })}
        onClick={onNext}
        disabled={tail === 0}
        aria-label="next slide"
        type="button"
      >
        <CaretRight className="text-3xl" />
      </button>
    </div>
  );
}

Controls.propTypes = {
  className: PropTypes.string,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  scrollPosition: PropTypes.shape({
    head: PropTypes.number,
    tail: PropTypes.number,
  }),
  indicators: PropTypes.bool,
  totalItemsCount: PropTypes.number,
  activeItem: PropTypes.number,
};
