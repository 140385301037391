/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import cn from "classnames";

import { Button } from "@components/button";
import { tagular } from "@cohesion/tagular";
import { useElemRef } from "@utils/useElemRef";
import { useMobile } from "@utils/component";

import { Controls } from "./controls";
import { Indicators } from "./indicators";

/**
 * Doing this to circumvent purgecss not
 * handling composed classes well
 */
const pageSizes = {
  1: "md:w-full", // supports planningToolkit carousel
  2: "md:w-1/2",
  3: "md:w-1/3",
  4: "md:w-1/4",
  5: "md:w-1/5",
};

const mobileItemSizes = {
  xs: "w-[45%]",
  sm: "w-2/3",
  lg: "w-90",
  full: "w-full",
};

export function Carousel({
  activeItem = 0,
  className = "",
  contained = true,
  mobileItemSize = "lg",
  pageSize = 3,
  scrollPadding = true,
  cardClassNames = "",
  listClassNames = "",
  tagularPosition = "CAROUSEL",
  prevArrowTagularPosition = "",
  nextArrowTagularPosition = "",
  tagularName = "",
  polite = false,
  showMobileControls = false,
  showMobileIndicators = false,
  link = null,
  tagularLocation = "SECTION",
  children,
}) {
  const [carouselRef, setRef] = useElemRef(node => node);
  const isMobile = useMobile();
  const { offsetWidth, scrollLeft, scrollWidth } = carouselRef || {};
  const [carouselItemWidth, setCarouselItemWidth] = useState(0);
  const [activeChild, setActiveChild] = useState(0);

  const [scrollPosition, setScrollPosition] = useState({
    pageWidth: 0,
    head: 0,
    tail: null,
  });

  const hasControls = children.length > parseInt(pageSize, 10);

  const getScrollPosition = elem => {
    // hax until refactor :point-down:
    // eslint-disable-next-line no-shadow
    const { offsetWidth, scrollLeft, scrollWidth } = elem || {};

    return {
      pageWidth: offsetWidth,
      head: scrollLeft,
      tail: scrollWidth - offsetWidth - scrollLeft,
    };
  };

  const scroll = left => {
    carouselRef.scroll({
      top: 0,
      left,
      behavior: "smooth",
    });
  };

  const next = useCallback(() => {
    const { head, tail, pageWidth } = getScrollPosition(carouselRef);
    scroll(tail >= pageWidth ? head + pageWidth : head + tail);

    tagular("click", {
      actionOutcome: "SLIDE",
      outboundUrl: "NULL",
      webElement: {
        location: tagularLocation,
        elementType: "BUTTON",
        position: nextArrowTagularPosition || tagularPosition,
        text: "NEXT ARROW",
        ...(tagularName && { name: tagularName }),
      },
    });
  }, [carouselRef, tagularPosition, tagularLocation, tagularName]);

  const prev = useCallback(() => {
    const { head, pageWidth } = getScrollPosition(carouselRef);

    scroll(head >= pageWidth ? head - pageWidth : 0);

    tagular("click", {
      actionOutcome: "SLIDE",
      outboundUrl: "NULL",
      webElement: {
        location: tagularLocation,
        elementType: "BUTTON",
        position: prevArrowTagularPosition || tagularPosition,
        text: "PREV ARROW",
        ...(tagularName && { name: tagularName }),
      },
    });
  }, [carouselRef, tagularPosition, tagularLocation, tagularName]);

  const handleScroll = debounce(
    () => {
      const position = getScrollPosition(carouselRef);

      setScrollPosition(position);

      // Calculate the active child index based on scroll position and carousel item width
      if (carouselItemWidth > 0) {
        const activeChildIndex = Math.round(position.head / carouselItemWidth);
        setActiveChild(activeChildIndex);
      }
    },
    20,
    { leading: true, trailing: true }
  );

  const handleScrollEnd = useMemo(
    () =>
      debounce(() => {
        const prevPosition = scrollPosition.head;
        const position = getScrollPosition(carouselRef);
        const progressing = position.head > prevPosition;
        if (progressing && isMobile) {
          tagular("swipe", {
            actionOutcome: "SLIDE",
            webElement: {
              location: tagularLocation || "CARDS",
              elementType: "ARROW",
              position: tagularPosition,
              name: tagularName,
              text: "",
            },
          });
        }
      }, 600),
    [isMobile, carouselRef]
  );

  // Updates the scroll position when properties we care about update on carouselRef
  useEffect(() => {
    setScrollPosition(getScrollPosition(carouselRef || {}));
  }, [offsetWidth, scrollLeft, scrollWidth, carouselRef]);

  /**
   * In instances where the carousel is tied to another component (eg mobile map), we can
   * provide the active item and have the carousel scroll to that as it's changed.
   */
  useEffect(() => {
    if (activeItem) {
      const { offsetLeft: item } = carouselRef.children[activeItem];
      scroll(item);
    }
  }, [activeItem]);

  // Retrieve carousel item width for active child index calculation
  useEffect(() => {
    if (carouselRef && carouselRef.children.length > 0) {
      // Assume equal width for all carousel items
      setCarouselItemWidth(carouselRef.children[0].offsetWidth);
    }
  }, [carouselRef, carouselRef?.children]);

  return (
    <>
      <div
        className={cn(
          {
            "max-w-full overflow-x-hidden px-4 lg:container": !contained,
          },
          { container: contained },
          className
        )}
      >
        <ul
          className={cn(
            "carousel flex overflow-x-scroll md:overflow-x-auto overflow-y-hidden -mx-4",
            listClassNames
          )}
          ref={setRef}
          onScroll={() => {
            handleScroll();
            handleScrollEnd();
          }}
          {...(polite ? { "aria-live": "polite" } : {})}
        >
          {children.map(child => {
            const { key } = child;

            return (
              <li
                key={key}
                className={cn(
                  "carousel-item flex-shrink-0 px-4 pb-2",
                  mobileItemSizes[mobileItemSize],
                  pageSizes[pageSize],
                  cardClassNames
                )}
              >
                {child}
              </li>
            );
          })}
        </ul>

        {(hasControls || link) && (
          <div
            className={cn(
              "md:flex items-center mt-4",
              showMobileControls ? "flex justify-center" : "hidden",
              hasControls ? "md:justify-end" : "md:justify-start"
            )}
          >
            {link && (
              <Button
                type="button"
                href={link.href}
                className="mr-10 btn"
                onClick={() => {
                  tagular("click", {
                    actionOutcome: "INTERNALLINK",
                    outboundUrl: `${process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_URL}${link.href}`,
                    webElement: {
                      location: "SECTION",
                      text: "VIEWALL",
                      elementType: "LINK",
                      position: tagularPosition,
                    },
                  });
                }}
              >
                {link.text}
              </Button>
            )}
            {hasControls && (
              <Controls
                onPrev={prev}
                onNext={next}
                scrollPosition={scrollPosition}
                tagularPosition={tagularPosition}
                tagularName={tagularName}
              />
            )}
          </div>
        )}

        <style jsx>{`
          .carousel {
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
            scrollbar-width: none;
            scroll-padding-right: ${scrollPadding ? "32px" : 0};
          }
          .carousel::-webkit-scrollbar {
            display: none;
          }
          .carousel-item {
            scroll-snap-align: start;
          }
        `}</style>
      </div>
      {link && (
        <div className="mt-10 text-center md:hidden">
          <Button
            type="button"
            href={link.href}
            className="btn"
            onClick={() => {
              tagular("click", {
                actionOutcome: "INTERNALLINK",
                outboundUrl: `${process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_URL}${link.href}`,
                webElement: {
                  location: "SECTION",
                  text: "VIEWALL",
                  elementType: "LINK",
                  position: tagularPosition,
                },
              });
            }}
          >
            {link.text}
          </Button>
        </div>
      )}

      {showMobileIndicators && (
        <Indicators activeChild={activeChild} scrollPosition={scrollPosition}>
          {children}
        </Indicators>
      )}
    </>
  );
}

export const carouselPropTypes = {
  activeItem: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  contained: PropTypes.bool,
  mobileItemSize: PropTypes.oneOf(["xs", "sm", "lg", "full"]),
  pageSize: PropTypes.number,
  cardClassNames: PropTypes.string,
  listClassNames: PropTypes.string,
  scrollPadding: PropTypes.bool,
  tagularPosition: PropTypes.string,
  prevArrowTagularPosition: PropTypes.string,
  nextArrowTagularPosition: PropTypes.string,
  tagularName: PropTypes.string,
  polite: PropTypes.bool,
  showMobileControls: PropTypes.bool,
  showMobileIndicators: PropTypes.bool,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  tagularLocation: PropTypes.string,
};

Carousel.propTypes = carouselPropTypes;
