import PropTypes from "prop-types";
import { Indicator } from "@icons";
import cn from "classnames";

const MAX_INDICATORS = 4;

export function Indicators({ children, activeChild, scrollPosition }) {
  const isTail = scrollPosition?.tail === 0;
  const middleIndex = Math.floor((children.length - 2) / 2);

  const isActiveFirst = i => i === 0 && activeChild === 0;
  const isActiveSecond = i =>
    i === 1 && activeChild > 0 && activeChild <= middleIndex;
  const isActiveThird = i =>
    i === 2 && activeChild > middleIndex && activeChild < children.length - 1;
  const isActiveLast = i =>
    i === MAX_INDICATORS - 1 && (activeChild === children.length - 1 || isTail);

  const getIsActive = i =>
    children.length > MAX_INDICATORS
      ? isActiveFirst(i) ||
        isActiveSecond(i) ||
        isActiveThird(i) ||
        isActiveLast(i)
      : activeChild === i;

  return (
    <div className="mt-4 flex justify-center items-center md:hidden">
      {[...Array(Math.min(MAX_INDICATORS, children.length))].map((_, i) => {
        const isActive = getIsActive(i);

        return (
          <Indicator
            key={`carousel-indicator-${i}`} // eslint-disable-line react/no-array-index-key
            width="18"
            className={cn("mx-0.5", isActive ? "text-black-200" : "text-black")}
          />
        );
      })}
    </div>
  );
}

Indicators.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  activeChild: PropTypes.number.isRequired,
  scrollPosition: PropTypes.shape({
    tail: PropTypes.number,
  }),
};

Indicators.defaultProps = {
  scrollPosition: {
    tail: 0,
  },
};
